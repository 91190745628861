import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Mailchimp from "../components/mailchimp"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-height: 100%;
`

const Logo = styled.div`
  text-align: center;
  padding: 15px;
  max-width: 450px;
  margin: 0 auto;

  a {
    display: block;
    margin: 0 auto;
    max-width: 250px;
  }

  img {
    width: 100%;
  }

  p {
    margin-top: 50px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  small {
    display: block;
    margin-bottom: 30px;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Claralist" />

    <Container>
      <div>
        <Logo>
          <Link to="/">
            <img src={require('../images/logo.png')} />
          </Link>

          <p>To-do list's, Tasks, Reminders, Notes</p>

          <small>Work smarter with Claralists's productivity tools. We are currently in private beta, to request an invite please join the waiting list below.</small>

          <Mailchimp />
        </Logo>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
