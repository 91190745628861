import React from 'react';
import MailchimpSubscribe, { FormHooks } from 'react-mailchimp-subscribe';

import SubscribeForm from './subscribe-form';

const Form = (props: FormHooks<{}>) => {
  return <SubscribeForm {...props} />;
};

const MailChimp = () => {
  const url = '//claralist.us3.list-manage.com/subscribe/post?u=a8ced33cb4938a129239e9d1e&amp;id=0e92b0c7e5';
  return <MailchimpSubscribe url={url} render={Form} />;
};

export default MailChimp;
