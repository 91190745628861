import React, { useState, ChangeEvent, FormEvent } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  position: relative;
  text-align: center;
  padding: 15px;
  max-width: 300px;
  min-height: 100px;
  margin: 0 auto;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
`;

const Message = styled.span`
  color: green;
  font-size: 0.8rem;
`;

const FormGroup = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const TextField = styled.input`
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 0.5rem;
  padding: 11px 16px 11px 16px;
  font-size: 1rem;
  display: block;
  width: 100%;
  margin-left: -16px;
  margin-right: -16px;

  &[disabled] {
    background: #f8f8f8;
  }
`;

const Button = styled.button`
  border: none;
  text-align: center;
  background: #000;
  color: #fff;
  border-radius: 0.5rem;
  padding: 12px 16px;
  font-size: 1rem;
  display: block;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  right: -18px;
  top: 0;
  min-width: 95px;

  &:hover {
    background: #ccc;
  }
`;

const SubscribeForm = (props: SubscribeFromProps) => {
  const { subscribe, status, message } = props;
  const [email, setEmail] = useState('');
  const [invalid, setInvalid] = useState('');

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvalid('');
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setInvalid('');

    if (email === '' || email.indexOf('@') === -1) {
      return setInvalid('Please enter a valid email address');
    }

    subscribe({ EMAIL: email });
  };

  const loading = status === 'sending';
  const error = status === 'error' ? message : '';
  const success = status === 'success' ? 'Success! we have sent you a link to confirm your subscription to the invitation waiting list' : '';

  return (
    <FormContainer>
      <FormGroup>
        <TextField
          value={email}
          onChange={handleEmailChange}
          placeholder="you@domain.com"
          type="email"
          name="email"
          required={true}
          readOnly={loading}
        />

        <Button type="submit" onClick={handleSubmit}>
          {loading ? 'Loading' : 'Invite me'}
        </Button>
      </FormGroup>

      {invalid && <ErrorMessage dangerouslySetInnerHTML={{ __html: invalid }} />}
      {error && <ErrorMessage dangerouslySetInnerHTML={{ __html: error }} />}
      {success && <Message dangerouslySetInnerHTML={{ __html: success }} />}

    </FormContainer>
  );
};

interface SubscribeFromProps {
  subscribe: (values: SubscribeFormFields) => void;
  status: string | null;
  message: string | null;
}

interface SubscribeFormFields {
  name?: string;
  EMAIL: string;
}

export default SubscribeForm;
